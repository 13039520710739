import { useEffect, useRef, useState } from "react";

export function injectPrintStylesOverride(printRef: HTMLIFrameElement) {
  const documentEl = printRef.contentWindow?.document;

  const style = documentEl?.createElement("style") as HTMLStyleElement;
  style.innerHTML = `
        @media print {
            @page {
                size: unset !important;
                margin: 8px !important;
            }

            .hiddenForCustomPrint {
              display: none !important;
            }
        }
    `;
  documentEl?.head.append(style);
  const rootChildElement = documentEl?.getElementById("spa-root")?.children[0];

  for (let node of rootChildElement?.children || []) {
    if (node.nodeName !== "MAIN") {
      node.classList.add("hiddenForCustomPrint");
    }
  }
}

export const usePrintHijack = ({
  enabled,
  spyElementId
}: {
  enabled: boolean;
  spyElementId: string;
}) => {
  const printFrameRef = useRef<HTMLIFrameElement>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [injectIframe, setInjectIframe] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [innerPageLoaded, setInnerPageLoaded] = useState(false);

  useEffect(() => {
    const printListener = function (e: KeyboardEvent) {
      if (enabled) {
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key == "p" ||
            e.charCode == 16 ||
            e.charCode == 112 ||
            e.keyCode == 80)
        ) {
          e.cancelBubble = true;
          e.preventDefault();

          e.stopImmediatePropagation();
          setShowLoader(true);
          setInjectIframe(true);
        }
      }
    };

    window?.addEventListener("keydown", printListener);

    return () => {
      window?.removeEventListener("keydown", printListener);
    };
  }, [enabled]);

  useEffect(() => {
    if (iframeLoaded && printFrameRef?.current?.contentDocument && enabled) {
      const interval = setInterval(() => {
        const spyElement =
          printFrameRef?.current?.contentDocument?.getElementById(spyElementId);
        if (spyElement) {
          setInnerPageLoaded(true);
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [
    printFrameRef?.current?.contentDocument,
    iframeLoaded,
    enabled,
    spyElementId
  ]);

  useEffect(() => {
    if (
      iframeLoaded &&
      printFrameRef.current?.contentWindow &&
      enabled &&
      innerPageLoaded
    ) {
      injectPrintStylesOverride(printFrameRef?.current);

      printFrameRef.current?.contentWindow?.print();
      setShowLoader(false);
      setInjectIframe(false);
      setIframeLoaded(false);
      setInnerPageLoaded(false);
    }
  }, [
    iframeLoaded,
    printFrameRef.current?.contentWindow,
    enabled,
    innerPageLoaded
  ]);

  return { printFrameRef, enabled, showLoader, injectIframe, setIframeLoaded };
};
